<template>
  <div class="hx-detail hx-pro" >
    <van-nav-bar :title="title" left-text="返回" fixed left-arrow @click-left="goBack"  />
    <div style="padding-top: 44px"></div>
    <van-tabs v-if="editId" color="#1073e5" v-model="activeName" style="margin-bottom: 12px" @change="handleClick">
      <van-tab title="详情" name="1">
        <van-pull-refresh v-model="isLoading" @refresh="onRefresh(activeName)">
          <div class="pm-content">
            <van-collapse v-model="activeNames">
              <van-collapse-item :title="equipment" name="1">
                <van-cell v-for="item in data.task_list" :key="item.index">
                  <van-row type="flex">
                    <van-col>部位：{{ item.part }}</van-col>
                  </van-row>
                  <van-row type="flex">
                    <van-col>方法：{{ item.task }}</van-col>
                  </van-row>
                  <van-row type="flex">
                    <van-col>内容：{{ item.param }}</van-col>
                  </van-row>
                  <van-row type="flex">
                    <van-col>标准：{{ item.param_detail }}</van-col>
                  </van-row>
                  <van-row v-if="item.type === 2" type="flex">
                    <van-col style="margin-top: 8px">
                      <van-radio-group v-model="item.reading" direction="horizontal">
                        <van-radio name="1">正常</van-radio>
                        <van-radio name="2">不正常</van-radio>
                      </van-radio-group>
                    </van-col>
                  </van-row>
                  <van-row v-if="item.type === 1" type="flex">
                    <van-col class="read">
                      <van-field v-model="item.reading" type="digit" name="读数" label="读数:" placeholder="请输入读数" />
                    </van-col>
                  </van-row>
                </van-cell>
              </van-collapse-item>
            </van-collapse>
          </div>
          <van-button style="width: 240px; margin: 10px auto 80px" block round type="primary" :disabled="read" @click="submitReading">提交</van-button>
          <div class="air"></div>
        </van-pull-refresh>
      </van-tab>
      <van-tab title="人员" name="2">
        <van-form class="pm-content">
          <van-list>
            <van-cell>
              <van-swipe-cell>
                <van-row type="flex">
                  <van-col span="10">执行人</van-col>
                </van-row>
              </van-swipe-cell>
            </van-cell>
            <van-cell v-for="item in member_list" :key="item.id" style="margin-top: 4px">
              <van-swipe-cell>
                <van-row type="flex">
                  <van-col span="10">{{ item.admin }}</van-col>
                </van-row>
                <template #right>
                  <van-button square text="删除" size="mini" type="danger" @click="memberRemove(item.id)" />
                </template>
              </van-swipe-cell>
            </van-cell>
          </van-list>
        </van-form>
        <div class="pm-content">
          <van-field v-model="member" is-link readonly label="执行人" placeholder="选择执行人" arrow-direction="down" @click="chooseMember"/>
          <van-popup v-model:show="showMemberPicker" round position="bottom">
            <van-search v-model="memberVal" placeholder="搜索人员" />
            <van-picker
                    :columns="memberColumns"
                    @cancel="showMemberPicker = false"
                    @confirm="memberConfirm"
            />
          </van-popup>
        </div>
        <van-button style="width: 240px; margin: 10px auto" block round type="primary" @click="memberAdd(2)">添加执行人</van-button>
      </van-tab>
      <van-tab title="时间" name="3">
        <van-form class="pm-content">
          <van-list>
            <van-cell>
              <van-swipe-cell>
                <van-row type="flex">
                  <van-col span="12">开始时间</van-col>
                  <van-col span="10">结束时间</van-col>
                </van-row>
              </van-swipe-cell>
            </van-cell>
            <van-cell v-for="item in time_list" :key="item.id" style="margin-top: 4px">
              <van-swipe-cell>
                <van-row type="flex">
                  <van-col span="12">{{ item.start_time }}</van-col>
                  <van-col span="10">{{ item.end_time }}</van-col>
                </van-row>
                <template #right>
                  <van-button square text="删除" size="mini" type="danger" @click="timeRemove(item.id)" />
                </template>
              </van-swipe-cell>
            </van-cell>
          </van-list>
        </van-form>
        <div class="pm-content">
          <van-field v-model="start_time" is-link readonly label="开始时间" placeholder="选择开始时间" arrow-direction="down" @click="startTimePicker = true"/>
          <van-popup v-model:show="startTimePicker" round position="bottom">
            <van-datetime-picker
                    v-model="startDate"
                    type="datetime"
                    title="选择开始时间"
                    :min-date="minDate"
                    :max-date="maxDate"
                    @cancel="startTimePicker = false"
                    @confirm="startTimeConfirm"
            />
          </van-popup>
          <van-field v-model="end_time" is-link readonly label="结束时间" placeholder="选择结束时间" arrow-direction="down" @click="endTimePicker = true"/>
          <van-popup v-model:show="endTimePicker" round position="bottom">
            <van-datetime-picker
                    v-model="endDate"
                    type="datetime"
                    title="选择结束时间"
                    :min-date="minDate"
                    :max-date="maxDate"
                    @cancel="endTimePicker = false"
                    @confirm="endTimeConfirm"
            />
          </van-popup>
        </div>
        <van-button style="width: 240px; margin: 10px auto" block round type="primary" @click="timeAdd">添加时间</van-button>
      </van-tab>
      <van-tab title="备件" name="4">
        <van-form class="pm-content" >
          <van-list>
            <van-cell>
              <van-swipe-cell>
                <van-row type="flex">
                  <van-col span="20">备件名称</van-col>
                  <van-col span="4"><p style="color: #666666">数量</p></van-col>
                </van-row>
                <van-row type="flex">
                  <van-col span="12"><p style="font-size: 12px;color: #666666">型号</p></van-col>
                </van-row>
              </van-swipe-cell>
            </van-cell>
            <van-cell v-for="item in parts_list" :key="item.id" style="margin-top: 4px">
              <van-swipe-cell>
                <van-row type="flex">
                  <van-col span="21">{{ item.name }}</van-col>
                  <van-col span="3">{{ item.number }}</van-col>
                </van-row>
                <van-row type="flex">
                  <van-col span="12"><p>{{ item.coding }}</p></van-col>
                </van-row>
                <template #right>
                  <van-button square text="删除" size="mini" type="danger" @click="partsRemove(item.id)" />
                </template>
              </van-swipe-cell>
            </van-cell>
          </van-list>
        </van-form>
        <div class="pm-content">
          <van-field  v-model="parts" is-link readonly label="备件" placeholder="选择备件" arrow-direction="down" @click="chooseParts"/>
          <van-popup v-model:show="showPartsPicker" round position="bottom">
            <van-search v-model="partsVal" placeholder="搜索备件" />
            <van-picker
                    :columns="partsColumns"
                    @cancel="showPartsPicker = false"
                    @confirm="partsConfirm"
            />
          </van-popup>
        </div>
        <div v-if="data.status === 1 || data.status === 100" class="parts-content">
          <p>当前数量：{{ partsNumber }}</p>
          <p>备件编码：{{ partsCoding }}</p>
          <p>所在库房：{{ partsStore }}</p>
          <p>备件型号：{{ partsSpec }}</p>
        </div>
        <div v-if="data.status === 1 || data.status === 100" class="pm-content">
          <van-field v-model="number" type="digit" name="备件数量" label="需要数量:" />
        </div>
        <van-button v-if="data.status === 1 || data.status === 100" style="width: 240px; margin: 10px auto" block round type="primary" @click="partsAdd()">添加备件</van-button>
        <van-button v-if="data.status === 1 || data.status === 100" style="width: 240px; margin: 10px auto" block round type="success" @click="requestParts">请求备件</van-button>
      </van-tab>
      <van-tab title="工单" name="5">
        <van-pull-refresh v-model="isLoading" @refresh="onRefresh(activeName)">
          <van-form class="pm-content" >
            <van-field v-model="data.code" name="编码" label="编码:" readonly />
            <van-field v-model="data.equipment" name="名称" label="名称:" readonly />
            <van-field v-model="data.admin" name="负责人" label="负责人:" readonly />
            <van-field v-model="data.arrange_time" is-link readonly label="安排时间" placeholder="选择安排时间" arrow-direction="down" @click="arrangeTimePicker = true"/>
            <van-popup v-model:show="arrangeTimePicker" round position="bottom">
              <van-datetime-picker
                      v-model="data.arrange_time"
                      type="datetime"
                      title="选择安排时间"
                      :min-date="minDate"
                      :max-date="maxDate"
                      @cancel="arrangeTimePicker = false"
                      @confirm="arrangeTimeConfirm"
              />
            </van-popup>
            <van-field v-model="data.complete_time" is-link readonly label="完成时间" placeholder="选择完成时间" arrow-direction="down" @click="completeTimePicker = true"/>
            <van-popup v-model:show="completeTimePicker" round position="bottom">
              <van-datetime-picker
                      v-model="data.complete_time"
                      type="datetime"
                      title="选择完成时间"
                      :min-date="minDate"
                      :max-date="maxDate"
                      @cancel="completeTimePicker = false"
                      @confirm="completeTimeConfirm"
              />
            </van-popup>
          </van-form>
          <van-button v-if="data.status === 10" style="width: 240px; margin: 10px auto" block round type="success" @click="handOut">派发</van-button>
        </van-pull-refresh>
      </van-tab>
    </van-tabs>

  </div>
</template>

<script>
import API from '@/api/workorder/out'
import ADMIN from '@/api/personnel/admin'
import PARTS from '@/api/stock/parts'
import Cookies from 'js-cookie'
import format from "@/utils/day"
export default {
  inject: ['goUrl', 'isEdit', 'add', 'update'],
  data() {
    return {
      title: '',
      data: {
        equipment: ''
      },
      member: '',
      parts: '',
        activeNames: ['1'],
      editId: null,
      name: '',
      read: true,
      equipment: null,
      admin_id: null,
      parts_id: null,
      start_time: null,
      end_time: null,
      partsNumber: null,
      partsCoding: null,
      partsStore: null,
      partsSpec: null,
      isLoading: true,
      activeName: 1,
      task_list: [],
      member_list: [],
      time_list: [],
      parts_list: [],
      adminList: [],
      memberColumns: [],
      partsColumns: [],
      type: 1,
      memberVal: '',
      partsVal: '',
      number: null,
      memberSearch: [],
      partsSearch: [],
      minDate: new Date(2020, 0, 1),
      maxDate: new Date(2025, 10, 1),
      startDate: new Date(),
      endDate: new Date(),
      showMemberPicker: false,
      showPartsPicker: false,
      startTimePicker: false,
      endTimePicker: false,
      completeTimePicker: false,
      arrangeTimePicker: false
    }
  },
  created() {
    const edit = this.isEdit()
    if (edit) {
      this.title = '委外工单详情'
      this.editId = edit - 0
      this.info()
    } else {
      this.title = '新增委外工单'
    }
  },
  methods: {
    async info() {
      const res = await API.getOne(this.editId)
      this.data = res.data
      this.equipment = res.data.equipment
      this.task_list = res.data.task_list
      this.member_list = res.data.member_list
      this.time_list = res.data.time_list
      this.parts_list = res.data.parts_list
      const arr = Cookies.get('pmsKey')
      let flag = false
      if(arr) {
          flag = arr.includes(this.editId)
      }
      if(flag) {
        this.read = false
      } else {
        console.log('无扫码记录')
      }
    },
    goBack() {
      this.goUrl('/workorder/out')
    },
    handleClick(name) {
      this.type = parseInt(name)
    },
    onRefresh(activeName) {
      this.info()
      this.activeName = activeName
      setTimeout(() => {
        this.isLoading = false;
      }, 1000)
    },
    submitReading() {
      let other_task = Object.assign({}, this.data.other_task)
      const data = {
        data: other_task,
        id: this.editId
      }
      API.submit(data).then(res => {
        if (res.data.error_code === 1) {
          this.$toast('提交完成')
        } else if (res.data.error_code === 11) {
          this.$toast('完成并生成保修单')
        } else {
          this.$toast(res.data.message)
        }
      })
      this.goUrl('/workorder/pm1')
    },
    memberRemove(id) {
      API.removeMember(id).then(res => {
        if (res.data.error_code !== 1) {
          this.$toast(res.data.message)
        } else {
          this.$toast('删除成功')
        }
      }).finally(() => {
        this.info()
      })
    },
    timeAdd() {
      const timeData = {
        end_time: this.end_time,
        start_time: this.start_time,
        workorder_id: this.editId
      }
      API.appendTime(timeData).then(res => {
        if (res.data.error_code === 1) {
          this.$toast('新增成功')
          this.info()
        } else {
          this.$toast(res.data.message)
        }
      })
    },
    timeRemove(id) {
      API.removeTime(id).then(res => {
        if (res.data.error_code !== 1) {
          this.$toast(res.data.message)
        } else {
          this.$toast('删除成功')
        }
      }).finally(() => {
        this.info()
      })
    },
    partsRemove(id) {
      API.removeParts(id).then(res => {
        if (res.data.error_code !== 1) {
          this.$toast(res.data.message)
        } else {
          this.$toast('删除成功')
        }
      }).finally(() => {
        this.info()
      })
    },
    startTimeConfirm(value) {
      this.start_time = format(value, 'YYYY-MM-DD HH:mm:ss')
      this.startTimePicker = false
    },
    arrangeTimeConfirm(value) {
      this.data.arrange_time = format(value, 'YYYY-MM-DD HH:mm:ss')
      this.arrangeTimePicker = false
    },
    completeTimeConfirm(value) {
      this.data.complete_time = format(value, 'YYYY-MM-DD HH:mm:ss')
      this.completeTimePicker = false
    },
    endTimeConfirm(value) {
      this.end_time = format(value, 'YYYY-MM-DD HH:mm:ss')
      this.endTimePicker = false
    },
    chooseMember() {
      ADMIN.adminList({limit: 500}).then(res => {
        this.adminList = res.data.data
        let arr = this.adminList.map((item) => {
          return Object.assign({}, {id: item.id, text: item.name})
        })
        this.memberColumns = arr
        this.memberSearch = arr
      })
      this.showMemberPicker = true
    },
    chooseParts() {
      PARTS.getList({limit:50}).then(res => {
        this.partsList = res.data.data
        let arr = this.partsList.map((item) => {
          return Object.assign({}, { id: item.id, text: item.name, code: item.code, number: item.number, store: item.store, spec: item.spec })
        })
        this.partsColumns = arr
        this.partsSearch = arr
      })
      this.showPartsPicker = true
    },
    memberConfirm(member) {
      this.admin_id = member.id
      this.member = member.text
      this.showMemberPicker = false
    },
    partsConfirm(parts) {
      console.log(parts)
      this.partsCoding = parts.code
      this.partsStore = parts.store
      this.parts_id = parts.id
      this.partsSpec = parts.spec
      this.parts = parts.text
      this.partsNumber = parts.number
      this.showPartsPicker = false
      this.partsVal = ''
    },
    partsAdd() {
      const partsData = {
        workorder_id: this.editId,
        parts_id: this.parts_id,
        number: this.number,
        remark: this.remark
      }
      API.appendParts(partsData).then(res => {
        if (res.data.error_code === 1) {
          this.$toast('新增成功')
          this.info()
        } else {
          this.$toast(res.data.message)
        }
      })
    },
    requestParts() {
      API.requestParts({ id: this.editId }).then(res => {
        if (res.data.error_code === 1) {
          this.$toast('请求成功，请去库房领取')
          this.info()
        } else {
          this.$toast(res.data.message)
        }
      })
    },
    memberAdd(engineer) {
      const memberData = {
        engineer: engineer,
        workorder_id: this.editId,
        admin_id: this.admin_id
      }
      API.appendMember(memberData).then(res => {
        if (res.data.error_code === 1) {
          this.$toast('新增成功')
          this.info()
        } else {
          this.$toast(res.data.message)
        }
      })
    },
    handOut() {
      const newData = {
        id: this.editId,
        arrange_time: this.data.arrange_time,
        end_time: this.data.end_time
      }
      API.handOut(newData).then(res => {
        if (res.data.error_code === 1) {
          this.$toast('派发成功')
          this.info()
        } else {
          this.$toast(res.data.message)
        }
      })
    },
    onSubmit(values) {
      if (values) {
        if (this.editId) {
          this.update(API, this.data, '更新成功', '/index')
        } else {
          this.add(API, this.data, '新增成功')
        }
      } else {
        console.log('error submit!!')
        return false
      }
      console.log('submit', values);
    }
  },
  watch: {
    // 搜索人员
    memberVal: {
      handler() {
        if (this.memberVal == '') {
          this.memberColumns = this.memberSearch
        } else {
          this.memberColumns = []
          this.memberSearch.forEach(item => {
            if (item.text.indexOf(this.memberVal) > -1) {
              this.memberColumns.push(item)
            }
          })
        }
      },
      immediate: true
    },
    partsVal: {
      handler() {
        if (this.partsVal == '') {
          this.partsColumns = this.partsSearch
        } else {
          this.partsColumns = []
          PARTS.partsList({name: this.partsVal}).then(res =>{
            this.partsList = res.data.data
            let arr = this.partsList.map((item) => {
              return Object.assign({}, { id: item.id, text: item.name, coding: item.coding, number: item.number, store: item.store, spec: item.spec })
            })
            this.partsColumns = arr
          })
        }
      },
      immediate: true
    }
  }
}
</script>

<style scoped>

</style>
